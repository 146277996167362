.quantity {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4.25rem;
    margin-top: theme('fields.spacing.default');
    padding: theme('fields.padding.default');
    border: 1px solid theme('colors.decorators.subtle');
    border-radius: theme('borderRadius.100');

    & svg {
        width: 0.75rem;
        height: 0.75rem;
        transform: rotate(90deg);
        fill: theme('colors.actions.secondary');
    }
}

.totals {
    opacity: 0.3;

    & > li + li {
        padding-top: theme('spacing.075');
        margin-top: theme('spacing.075');
        border-top: 1px solid theme('colors.decorators.subtle');
    }
}
