.FilterSection_header___5RF2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.LoadingIcon_animate__Hg27L {
    animation-name: LoadingIcon_rotate__UL5Jg;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes LoadingIcon_rotate__UL5Jg {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ExistingSelector_grid__3Wo8Q {

    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 24px;
}

    @media (min-width: 768px) {.ExistingSelector_grid__3Wo8Q {

        grid-template-columns: repeat(2, minmax(0, 1fr));

        grid-template-rows: repeat(4, auto);
        row-gap: 24px;
        -moz-column-gap: 32px;
             column-gap: 32px;
    }

        .ExistingSelector_grid__3Wo8Q:has(.ExistingSelector_reasonsToBuy__UayuZ) .ExistingSelector_incentives__6VPDr {
            grid-area: 4 / 1 / 5 / 2; /* Position left column, below reasons to buy. */
        }
    }

.ExistingSelector_image__Nc_LC {
    width: 10rem;
    margin: 0 auto;
}

@media (min-width: 768px) {

.ExistingSelector_image__Nc_LC {
        width: 12.5rem;

        grid-area: 2 / 1 / 3 / 2 /* Position left column, below header. */
}
    }

@media (min-width: 1024px) {

.ExistingSelector_image__Nc_LC {
        width: 17.5rem
}
    }

@media (min-width: 768px) {

.ExistingSelector_header__Hq0Ua {
        grid-area: 1 / 1 / 2 / 3 /* Position top, across both columns. */
}

.ExistingSelector_reasonsToBuy__UayuZ {
        grid-area: 3 / 1 / 4 / 2 /* Position left column, below image. */
}

.ExistingSelector_treatmentSelector__IazRx {
        grid-area: 2 / 2 / 5 / 3 /* Position right column, below header. */
}

.ExistingSelector_incentives__6VPDr {
        grid-area: 3 / 1 / 4 / 2 /* Position left column, below image. Is changed if reasonsToBuy is rendered. */
}
    }

.TreatmentUnavailable_quantity__ILlcO {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4.25rem;
    margin-top: 4px;
    padding: 12px;
    border: 1px solid #CAD8E2;
    border-radius: 12px;
}

    .TreatmentUnavailable_quantity__ILlcO svg {
        width: 0.75rem;
        height: 0.75rem;
        transform: rotate(90deg);
        fill: #007ACC;
    }

.TreatmentUnavailable_totals__VoBKP {
    opacity: 0.3;
}

.TreatmentUnavailable_totals__VoBKP > li + li {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid #CAD8E2;
    }

.FilterTagCloud_cloud__lyzZQ {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
}

.FilterTagCloud_tag__dH11G {
    display: flex !important;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}

.FilterTagCloud_button__0nig_ {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: -4px;
    margin-right: -4px;

    padding: 4px;
}

.SortAndFilterDialog_dialog__ldG_t {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    height: 100dvh;

}

    @media (min-width: 768px) {.SortAndFilterDialog_dialog__ldG_t {
        align-items: center

}
    }

.SortAndFilterDialog_panel__WMZqg {
    display: flex;
    flex-direction: column;
    max-width: 38.75rem;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background-color: #FFFFFF;
    
    overflow: hidden;
    transition: transform 300ms ease-in-out;
}

@media (min-width: 768px) {

.SortAndFilterDialog_panel__WMZqg {
        max-height: calc(100% - 10.125rem);
        border-radius: 24px;
}
    }

.SortAndFilterDialog_panel__WMZqg {

    z-index: 0;

}

.SortAndFilterDialog_panelWindowHeight__AmOdw {
    height: calc(100% - 3.25rem);
}

.SortAndFilterDialog_header__5tcsB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 1px solid #CAD8E2;
    flex-grow: 0;
    flex-shrink: 0;
}

.SortAndFilterDialog_closeButton__I4Inu {
    margin: -8px;

    padding: 8px;

}

